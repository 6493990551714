/* istanbul ignore file */
import { hot } from 'react-hot-loader/root'
import React from 'react'
import { IntlProvider } from 'react-intl'
import { messages } from 'commons/js/messages'
import { defaultLocale, currentLocale } from 'commons/js/locale'
import Cookies from './components/Cookies'
import { BrowserWrapper, EPProvider } from '@elparking/components'
import theme from './theme'
import convertConstantToEPProviderFormat from 'commons/js/util/coverterGlobalConfig'
import { AnalyticsProvider } from '@elparking/analytics'

const App = (props) => {
    const config = convertConstantToEPProviderFormat()
    return (
        <IntlProvider
          locale={currentLocale}
          defaultLocale={defaultLocale}
          messages={messages[currentLocale]}
        >
            <EPProvider theme={theme} {...config}>
                <BrowserWrapper>
                    <AnalyticsProvider analyticService={window.utag}>
                        <Cookies {...props} />
                    </AnalyticsProvider>
                </BrowserWrapper>
            </EPProvider>
        </IntlProvider>
    )
}

export default hot(App)
