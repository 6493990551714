/* istanbul ignore file */
import { initTealium } from 'commons/js/services/analytics'
import App from './App'
import render from 'commons/js/util/render'
import { init } from 'commons/js/util/reporter'
import { error } from '@elparking/utils/src/logger'

init()

const element = document.querySelector('.js-cookie-banner')
const dataset = element.dataset

function initAnalytics (onInit = () => {}) {
    try {
        if (window.initializedAnalytics) {
            return
        }
        window.initializedAnalytics = true
        window.App && window.App.initAnalytics && window.App.initAnalytics()
        initTealium((onInit))
    } catch (e) {
        error('Error loading analytics')
    }
}

const dataProps = {
    privacyUrl: dataset.privacyUrl,
    initAnalytics: initAnalytics,
}

render({ App, element, props: { ...dataProps } })
