import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Icon, Flex, Box, Copy, Button, Check, CheckButton } from '@elparking/components'
import PropTypes from 'prop-types'
import { ScrollContainer, ScrollFadeOut } from './Scroll'

const saveAllState = {analytic: true, advertising: true, preference: true, technical: true}
const defaultState = {analytic: false, advertising: false, preference: false, technical: true}

const successMessages = {
    'analytic': <FormattedMessage defaultMessage='Dispones de una mejora continua en la experiencia de navegación.' />,
    'preference': <FormattedMessage defaultMessage='Dispones de una navegación y contenidos personalizados.' />,
    'advertising': <FormattedMessage defaultMessage='Dispones de una personalización de la publicidad adaptada a ti.' />,
}

const errorMessages = {
    'analytic': <FormattedMessage defaultMessage='No podemos ofrecerte una mejora continua en la experiencia de navegación.' />,
    'preference': <FormattedMessage defaultMessage='No podemos ofrecerte una navegación y contenidos personalizados.' />,
    'advertising': <FormattedMessage defaultMessage='No podemos ofrecerte una personalización de publicidad adaptada a ti.' />,
}

const Message = ({ color, icon, message }) => <Box background={color + '10'} p='10px'>
    <Copy padding='0' fontSize='xxxSmall' fontColor='gray' fontWeight='regular'>
        <Flex alignItems='center'>
            <Box paddingRight='1em'><Icon type={icon} fontSize='xxSmall' fontColor={color} /></Box>
            <Box>{message}</Box>
        </Flex>
    </Copy>
</Box>

Message.propTypes = {
    icon: PropTypes.string,
    color: PropTypes.string,
    message: PropTypes.node,
}

const Checker = ({ onChange, checked, id, ...restProps }) =>
    <Flex w='100%' justifyContent='space-between'>
        <Box><Box paddingRight='1em'>
            <Copy padding='1em 0 0 0' fontSize='xxSmall' fontWeight='regular'>
                {checked
                    ? <Message color='success' icon='ok' message={successMessages[id]} />
                    : <Message color='error' icon='warning-line' message={errorMessages[id]} />
                }
            </Copy>
        </Box></Box>
        <Box textAlign='right' pt='1em'>
            <Check
              {...restProps}
              id={id}
              checked={checked}
              dataTest={'check-cookie-' + id}
              justifyContent='start'
              checkButtonElement={CheckButton.toggle}
              fontSize='small'
              label=''
              fontColor='gray'
              fontWeight='book'
              type='checkbox'
              onChange={({ target }) => onChange(target.checked)} />
        </Box>
    </Flex>

Checker.propTypes = {
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    checked: PropTypes.bool.isRequired,
}

const CookiesSelector = ({ onSave, privacyUrl, defaultCookies }) => {
    const [ cookies, setCookies ] = useState(defaultCookies || defaultState)
    return <Box w='100%' position='relative'>
        <Box mb='2em' mt='-2em'>
            <Copy padding='0' fontSize='xLarge' fontWeight='medium'>
                <FormattedMessage defaultMessage='Personaliza tus cookies' />
            </Copy>
        </Box>
        <ScrollContainer height={['100%', '100%', 'calc(100vh - 300px)']}>
            <Copy padding='0 0 1em 0' fontSize='medium' fontWeight='medium'>
                <FormattedMessage defaultMessage='Tu privacidad' />
            </Copy>
            <Copy padding='0 0 1em 0' fontSize='xxSmall' fontWeight='regular' textAlign='justify'>
                <FormattedMessage defaultMessage='Cuando visites un sitio web, se puede almacenar o recuperar información en tu navegador utilizando cookies. Esta información puede referirse a tus preferencias o tus hábitos de navegación en la web y de tu dispositivo.' />
            </Copy>
            <Copy padding='0' fontSize='xSmall' fontWeight='regular' textAlign='justify'>
                <FormattedMessage defaultMessage='La información que se obtiene no siempre te identificará personalmente. Como respetamos tu privacidad, puedes aceptar o rechazar el uso de cookies en función de la finalidad en este panel de configuración. Sin embargo, si bloqueas el uso de algunas tipologías de cookies, puede que tu experiencia en el uso de la web se vea afectada. Más información {link}.' values={{
                    link: (
                        <Copy as='a' fontSize='xxSmall' fontColor='main' href={privacyUrl} target='_blank'>
                            <FormattedMessage defaultMessage='política de cookies'/>
                        </Copy>
                    ),
                }} />
            </Copy>
            <Copy padding='1em 0' fontSize='medium' fontWeight='medium'>
                <FormattedMessage defaultMessage='Cookies técnicas' />
            </Copy>
            <Copy padding='0' fontSize='xxSmall' fontWeight='regular'>
                <FormattedMessage defaultMessage='Permiten al usuario navegar por el sitio web y utilizar los diferentes servicios que en ella se ofrecen (como, por ejemplo, gestionar el área privada, cumplimentar formularios, gestionar este panel de configuración o evitar posibles usos fraudulentos). Se utilizan cookies propias.' />
            </Copy>
            <Copy fontSize='xxxSmall' fontWeight='regular' textAlign='right'>
                <FormattedMessage defaultMessage='Activas siempre' />
            </Copy>
            <Copy padding='1em 0' fontSize='medium' fontWeight='medium'>
                <FormattedMessage defaultMessage='Cookies analíticas' />
            </Copy>
            <Copy padding='0' fontSize='xxSmall' fontWeight='regular'>
                <FormattedMessage defaultMessage='Se utilizan para el seguimiento y análisis del comportamiento de navegación de los usuarios y la medición de la actividad de los sitios web, con el fin de introducir mejoras. En todo caso, la información se utiliza de forma agregada y siempre anónima. Se utilizan cookies tanto propias como de terceros.' />
            </Copy>
            <Checker
              onChange={(value) => setCookies({
                ...cookies,
                analytic: value,
              })}
              id='analytic'
              checked={cookies.analytic}
            />
            <Copy padding='1em 0' fontSize='medium' fontWeight='medium'>
                <FormattedMessage defaultMessage='Cookies de preferencias o personalización' />
            </Copy>
            <Copy padding='0' fontSize='xxSmall' fontWeight='regular'>
                <FormattedMessage defaultMessage='Permiten recordar información para que el usuario pueda acceder a los servicios con determinadas características y así pueda personalizar su experiencia en la navegación web (p.e. el idioma, patrones de navegación, contenido del servicio, etc..). Se utilizan cookies propias.' />
            </Copy>
            <Checker
              onChange={(value) => setCookies({
                ...cookies,
                preference: value,
              })}
              id='preference'
              checked={cookies.preference}
            />
            <Copy padding='1em 0' fontSize='medium' fontWeight='medium'>
                <FormattedMessage defaultMessage='Cookies publicitarias' />
            </Copy>
            <Copy padding='0' fontSize='xxSmall' fontWeight='regular'>
                <FormattedMessage defaultMessage='Son aquéllas que recogen información sobre la navegación del usuario con la finalidad de elaborar un perfil y así poder ofrecer publicidad de manera personalizada, mostrando anuncios que pudieran ser de tu interés. Se utilizan cookies tanto propias como de terceros.' />
            </Copy>
            <Box mb={['1em', '1em', '50px']}>
                <Checker
                  onChange={(value) => setCookies({
                      ...cookies,
                      advertising: value,
                  })}
                  checked={cookies.advertising}
                  id='advertising'
                />
            </Box>
        </ScrollContainer>
        <ScrollFadeOut display={['none', 'none', 'block']} />
        <Flex flexDirection={['column', 'column', 'row']} justifyContent='flex-end'>
            <Box w={['100%', '100%', '300px']} paddingTop='1em'>
                <Button onClick={() => onSave(cookies)} w='100%' width='100%' data-test='cookies-button-save'>
                    <FormattedMessage defaultMessage='Guardar configuración' />
                </Button>
            </Box>
            <Box w={['100%', '100%', '300px']} paddingLeft={['0', '0', '1em']} paddingTop='1em'>
                <Button.bordered onClick={() => onSave(saveAllState)} width='100%' dataTest='cookies-button-save-all'>
                    <FormattedMessage defaultMessage='Aceptar todas' />
                </Button.bordered>
            </Box>
        </Flex>
    </Box>
}

CookiesSelector.propTypes = {
    defaultCookies: PropTypes.object,
    onSave: PropTypes.func,
    privacyUrl: PropTypes.string,
}

export default CookiesSelector
