/* istanbul ignore file */
import { Box } from '@elparking/components'
import styled from 'styled-components'

export const ScrollContainer = styled(Box)`
    overflow-x: hidden;
    overflow-y: scroll;
    min-height: ${({minHeight}) => minHeight};
`

ScrollContainer.defaultProps = {
    minHeight: '200px',
}

export const ScrollFadeOut = styled(Box)`
    height: 45px;
    right: 0;
    left: 0;
    margin-top: -45px;
    position: absolute;
    background: linear-gradient(0deg, white, transparent);
`
