/* istanbul ignore file */
import { localStorage } from '@elparking/utils'
import { TRADENAME } from 'commons/js/constants'
const COOKIE_CONFIG = TRADENAME + ':cookiesConfiguration'
const COOKIE_ACCEPTED_KEY = TRADENAME + ':cookiesAccepted'

export function getCookieTypes () {
  const cookiesTypes = localStorage.get(COOKIE_CONFIG)
  return cookiesTypes ? { ...cookiesTypes, technical: true } : null
}

export function getCookieAccepted () {
  return !!localStorage.get(COOKIE_ACCEPTED_KEY) && !!localStorage.get(COOKIE_CONFIG).technical
}

export function setCookieTypes (cookieTypes) {
  localStorage.save(COOKIE_ACCEPTED_KEY, 'true')
  localStorage.save(COOKIE_CONFIG, cookieTypes)
}

export function updateCookies (cookies, cookieTypes) {
  cookies.forEach(cookie => {
      if (!cookieTypes[cookie.type]) {
          window.addEventListener('unload', () => {
              document.cookie = `${cookie.name}=null; expires=Thu, 01 Jan 1970 00:00:00 GMT`
          })
      }
  })
}
